<template>
  <div>
    <!--Students Area Start-->
    <div class="teachers-area" v-show="showExcellentStudents">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title" style="margin-bottom: 80px">
                <h3>{{ ExcellentSTDs }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="students-image-slider">
          <div
            v-for="excellent in ExcellentStudents"
            :key="excellent.postId"
            class=""
          >
            <div class="single-teacher-item">
              <div class="single-teacher-image">
                <a
                  ><img
                    width="100%"
                    height="auto"
                    loading="lazy"
                    v-bind:src="
                      'https://api2.yuniv.net:444/images/post/' +
                      excellent.postImage
                    "
                    alt=""
                /></a>
              </div>
              <div class="single-teacher-text single-teacher-text1">
                <h3>
                  <a>{{ excellent.postTitle }}</a>
                </h3>
                <h4>{{ excellent.postSubTitle }}</h4>
                <p>{{ excellent.url }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Students Area-->

    <!-- testimonials start -->

    <div class="testimonial-area" v-show="showTestimonials">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 offset-lg-0 col-md-12 col-12">
            <div class="row">
              <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div
                  class="testimonial-image-slider text-center"
                  style="direction: initial"
                >
                  <div
                    class="sin-testiImage"
                    v-for="testimonials in Testimonials"
                    :key="testimonials.postId"
                    aria-labelledby="title"
                  >
                    <img
                      loading="lazy"
                      alt=""
                      width="100%"
                      height="auto"
                      v-bind:src="
                        'https://api2.yuniv.net:444/images/post/' +
                        testimonials.postImage
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="testimonial-text-slider text-center"
              style="direction: ltr"
              aria-labelledby="title"
            >
              <div
                class="sin-testiText"
                v-for="testimonials in Testimonials"
                :key="testimonials.postId"
              >
                <h2>{{ testimonials.postTitle }}</h2>
                <p style="margin-bottom: -18rem">
                  {{ testimonials.postSubTitle }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- testimonials end -->

    <!--Labs Area Start-->
    <div class="teachers-area" v-show="showLabs">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title" style="margin-bottom: 80px">
                <h3>{{ LabsT }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="Labs-image-slider">
          <div v-for="labs in Labs" :key="labs.postId" class="">
            <div class="single-teacher-item single-teacher-item1">
              <div class="single-teacher-image single-teacher-image1">
                <a
                  ><img
                    width="100%"
                    height="auto"
                    loading="lazy"
                    v-bind:src="
                      'https://api2.yuniv.net:444/images/post/' + labs.postImage
                    "
                    alt=""
                /></a>
              </div>
              <div class="single-event-item single-event-item1">
                <div class="single-event-text single-event-text2">
                  <h3>
                    <router-link
                      href="#"
                      aria-label="title"
                      :to="{
                        name: 'ComputerLabs',
                        params: { id: labs.postID },
                      }"
                      >{{ labs.postTitle }}</router-link
                    >
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12" style="text-align: left;padding:30px 0 0">
          <router-link
            href="#"
            aria-label="title"
            :to="{
              name: 'Labs'
            }"
            class="button-default button-large"
          >
            {{ BtnMore }} <i class="fa fa-chevron-left"></i
          ></router-link>
        </div>
        </div>
      </div>
    </div>
    <!--End of Labs Area-->
  </div>
</template>

<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";

export default {
  data() {
    return {
      showExcellentStudents: true,
      showTestimonials: true,
      showLabs: true,
      ExcellentSTDs: "",
      LabsT: "",
      ExcellentStudents: [],
      Testimonials: [],
      Labs: [],
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      BtnMore: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.ExcellentSTDs = self.translate[0]["Home"]["ExcellentSTDs"];
      self.LabsT = self.translate[0]["HeaderAndFooter"]["Labs"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("PostId", "");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Home.Sec.Testimonials");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "6");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      }).then(function (response) {
        self.Testimonials = response.data;

        var arr = response.data;

        if (arr.length != 0) {
          self.showTestimonials = true;
        } else {
          self.showTestimonials = false;
        }

        var bodyFormDataE = new FormData();
        bodyFormDataE.append("check", "getPost");
        bodyFormDataE.append("PostId", "");
        bodyFormDataE.append("Lang", localStorage.getItem("lang"));
        bodyFormDataE.append("FbrnId", "");
        bodyFormDataE.append("Type", "Univ.ExStudents");
        bodyFormDataE.append("ParentId", "NTROOT0");
        bodyFormDataE.append("PostName", "");
        bodyFormDataE.append("Pno", "-1");
        axios({
          method: "post",
          url: "https://api2.yuniv.net:444/our_team/getPost",
          data: bodyFormDataE,
        }).then(function (response) {
          var arr = response.data;
          if (arr.length != 0) {
            self.showExcellentStudents = true;
            self.ExcellentStudents = response.data;
          } else {
            self.showExcellentStudents = false;
          }

          var bodyFormDataL = new FormData();
          bodyFormDataL.append("check", "getPost");
          bodyFormDataL.append("PostId", "");
          bodyFormDataL.append("Lang", localStorage.getItem("lang"));
          bodyFormDataL.append("FbrnId", "");
          bodyFormDataL.append("Type", "Univ.labs");
          bodyFormDataL.append("ParentId", "NTROOT0");
          bodyFormDataL.append("PostName", "");
          bodyFormDataL.append("Pno", "-1");
          axios({
            method: "post",
            url: "https://api2.yuniv.net:444/our_team/getPost",
            data: bodyFormDataL,
          }).then(function (response) {
            var arr = response.data;
            if (arr.length != 0) {
              self.showLabs = true;
              self.Labs = response.data;
            } else {
              self.showLabs = false;
            }
            document.getElementById("VueMainJsNewTouch")?.remove();
            let recaptchaScript = document.createElement("script");
            recaptchaScript.setAttribute("src", "/js/main.js");
            recaptchaScript.id = "VueMainJsNewTouch";
            document.head.appendChild(recaptchaScript);
          });
        });
      });
    },
  },
};
</script>
<style scoped>
.testimonial-area .container .row {
  flex-direction: column;
}
</style>
