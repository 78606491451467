<template>
  <div class="fun-factor-area" v-show="showFacts" style="padding: 60px 0 67px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper white">
            <div class="section-title" style="margin-bottom: 50px">
              <h3>{{ impFact }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- <div class="row"></div> -->
        <div class="col-lg-2">
          <div class="single-fun-factor">
            <h4>{{ NoVisitor }}</h4>
            <h5>
              <!-- <span>{{ visitorCount }}</span> -->
              <span >{{ visitorCount_ }}</span>
            </h5>
          </div>
        </div>
        <div class="col-lg-10 col-12">
          <div class="row">
            <div
              v-for="Facts in Facts"
              :key="Facts.postId"
              class="col-lg-3 col-md-3 col-12"
            >
              <div class="single-fun-factor">
                <h4>{{ Facts.postTitle }}</h4>
                <h5>
                  {{ Facts.postSubTitle }}
                  <!-- <span class="counter">{{ Facts.postSubTitle }}</span> -->
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";
// import { OAuth2Client } from 'google-auth-library';

export default {
  data() {
    return {
      showFacts: true,
      Facts: [],
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      impFact: "",
      VisitorCount: "",
      NoVisitor: "",
      apiUrl: "",
      visitorCount: "",
      visitorCount_: "",
      visitorCountLast: "",
      ipAddress: null
    };
  },
  mounted() {
    var self_ = this;
 
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
    // self_.getIpAddress();
    
  },
  methods: {
    // async getIpAddress() {
    //   var self = this;
    //   try {
    //     if(localStorage.getItem('ipAddress') == null || localStorage.getItem('ipAddress') == '' ){
    //     const response = await axios.get('https://api.ipify.org?format=json');
    //     this.ipAddress = response.data.ip;
    //     localStorage.setItem('ipAddress',self.ipAddress)
    //     self.incrementVisitorCount();
    //     }
       
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.error('Failed to get IP address:', error);
    //   }
    // },
    // incrementVisitorCount() {
    //   this.visitorCount++;
    // },
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }

      self.impFact = self.translate[0]["Home"]["impFact"];
      self.NoVisitor = self.translate[0]["Home"]["NoVisitor"];

      // if (localStorage.getItem("countervisit") == "false") {
      //   localStorage.setItem("countervisit", "true");
      //   axios({
      //     method: "get",
      //     url:
      //       "https://api.countapi.xyz/hit/yuinv.net/344d410f-0a40-4034-9301-6b3b54476806",
      //   }).then(function (response) {
      //     self.VisitorCount = response.data.value;
      //   });
      // } else {
      //   axios({
      //     method: "get",
      //     url:
      //       "https://api.countapi.xyz/get/yuinv.net/344d410f-0a40-4034-9301-6b3b54476806",
      //   }).then(function (response) {
      //     self.VisitorCount = response.data.value;
      //   });
      // }

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("PostId", "");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Home.Sec.Facts");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "8");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      }).then(function (response) {
        self.Facts = response.data;
        var arr = response.data;
        if (arr.length != 0) {
          self.showFacts = true;
        } else {
          self.showFacts = false;
        }
       
      });
      var bodyFormDataV = new FormData();
      bodyFormDataV.append("check", "getPost");
      bodyFormDataV.append("PostId", "");
      bodyFormDataV.append("Lang", 'Ar');
      bodyFormDataV.append("FbrnId", "");
      bodyFormDataV.append("Type", "Vistitor");
      bodyFormDataV.append("ParentId", "NTROOT0");
      bodyFormDataV.append("PostName", "");
      bodyFormDataV.append("Pno", "1");
        axios({
          method: "post",
          url: "https://api2.yuniv.net:444/our_team/getPost",
          data: bodyFormDataV,
        }).then(function (response) {
          var arr2 = response.data;
          self.visitorCount_=arr2[0]['postTitle']
        })
        self.incrementVisitor();
    },
    incrementVisitor() {
      var bodyFormData = new FormData();
        bodyFormData.append("check", "getPost");
        bodyFormData.append("PostId", "");
        bodyFormData.append("Lang", 'Ar');
        bodyFormData.append("FbrnId", "");
        bodyFormData.append("Type", "Vistitor");
        bodyFormData.append("ParentId", "NTROOT0");
        bodyFormData.append("PostName", "");
        bodyFormData.append("Pno", "1");
        axios({
          method: "post",
          url: "https://api2.yuniv.net:444/our_team/getPost",
          data: bodyFormData,
        }).then(function (response) {
          var arr2 = response.data;
          self.visitorCount_=arr2[0]['postTitle']
          self.visitorCountLast=String(parseInt(arr2[0]['postTitle'])+1)
      var bodyFormFooterData = new FormData();
      bodyFormFooterData.append("check", "u");
      bodyFormFooterData.append("PostId", arr2[0]['postID']);
      bodyFormFooterData.append("MTYPE", "Vistitor");
      bodyFormFooterData.append("ParentId", "NTROOT0");
      bodyFormFooterData.append("PostName", self.visitorCountLast);
      bodyFormFooterData.append("PostTitle", self.visitorCountLast);
      bodyFormFooterData.append("PostIsHome", "");
      bodyFormFooterData.append("PostDsc", "");
      bodyFormFooterData.append("PostDetails", "");
      bodyFormFooterData.append("PostImage", "");
      bodyFormFooterData.append("FbrnId", "U");
      bodyFormFooterData.append("FusrId", "0");
      bodyFormFooterData.append("Fstate", "1");
      bodyFormFooterData.append("LangId", "Ar");
      bodyFormFooterData.append("forms", JSON.stringify([]));
      bodyFormFooterData.append("file", "");
      bodyFormFooterData.append("checkFile", "");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/OurTeamPost",
        data: bodyFormFooterData,
      })
        .then(function (response) {
// eslint-disable-next-line no-console
    console.log(response.data);
    });
    });
  }
  },
};
</script>
