<!-- @format -->
<template>
  <div>
    <Loading v-show="show" />

    <!-- new slider start -->
    <div class="slideshow-container" v-show="showSlider">
      <div
        class="mySlides fade2"
        v-for="slider in sliders"
        :key="slider.postId"
      >
        <img
          loading="lazy"
          width="1350"
          height="450"
          alt=""
          v-bind:src="
            'https://api2.yuniv.net:444/images/post/' + slider.postImage
          "
          style="width: 100%"
        />
        <div class="text">{{ slider.postTitle }}</div>
      </div>
      <div style="text-align:center;position: absolute;bottom: 5px;right: 50%;">
  <span class="dot"  v-for="slider in sliders" :key="slider.postId"></span>
</div>
    </div>
    <!-- <br /> -->
    <!-- <div style="text-align:center" v-show="false"> -->

    <!-- </div> -->

    <!-- new slider end  -->

    <comp />

    <!-- About Start -->
    <keep-alive>
      <HomeAbout />
    </keep-alive>
    <!-- About End -->

    <!-- Know Univ Start -->
    <keep-alive>
      <KnowUniv />
    </keep-alive>
    <!-- Know Univ End -->

    <!-- Fact start -->
    <keep-alive>
      <Facts />
    </keep-alive>
    <!-- Fact end -->

    <!-- News start -->
    <keep-alive>
      <HomeNews />
    </keep-alive>
    <!-- News end -->

    <!-- service start -->
    <keep-alive>
      <Services />
    </keep-alive>
    <!-- service end -->
    <keep-alive>
      <TestmoEexStdLabs />
    </keep-alive>

    <!-- Activity start -->
    <keep-alive>
      <Activity />
    </keep-alive>
    <!-- Activity end -->

    <!--Videos Area Start-->
    <keep-alive>
      <Videos />
    </keep-alive>
    <!--End of Videos Area-->

    <!-- Partners start -->
    <keep-alive>
      <Partners />
    </keep-alive>
    <!-- Partners end -->
  </div>
</template>

<script>
import Loading from "./Loading";
import HomeAbout from "./home/HomeAbout";
import KnowUniv from "./home/KnowUniv";
import Facts from "./home/Facts";
import HomeNews from "./home/HomeNews";
import Services from "./home/Services";
import Activity from "./home/Activity";
import Videos from "./home/Videos";
import Partners from "./home/Partners";
import TestmoEexStdLabs from "./home/Testmo-exStd-labs.vue";
import comp from "./home/comp.vue";

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
    HomeAbout,
    KnowUniv,
    Facts,
    HomeNews,
    Services,
    Activity,
    Videos,
    Partners,
    TestmoEexStdLabs,
    comp,
  },
  data() {
    return {
      show: true,
      showSlider: true,
      sliders: [],
      direction: "right",
      lang: "",
      LastNews: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      if (localStorage.getItem("Changelang") == "true") {
        location.reload();
      }
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        self.direction = "right";
      } else {
        self.translate = self.EnTranslat;
        self.direction = "left";
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.LastNews = self.translate[0]["Home"]["LastNews"];

      var bodyFormData1 = new FormData();
      bodyFormData1.append("check", "getPost");
      bodyFormData1.append("PostId", "");
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("FbrnId", "");
      bodyFormData1.append("Type", "Univ.Home.MainSlider");
      bodyFormData1.append("ParentId", "NTROOT0");
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          var arr = response.data;
          self.sliders = response.data;
          if (arr.length == 0) self.showSlider = false;
          else self.showSlider = true;

          setTimeout(() => {
            self.show = false;
            document.querySelectorAll("div.loader").forEach(function (element) {
              element.setAttribute("hidden", true);
            });
          }, 100);
        })
        .catch(function () {});
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .text {
    bottom: 80px !important;
  }
}
@media (max-width: 480px) {
  .text {
    bottom: 52px !important;
  }
}

.down_nt {
  margin-bottom: 30px;
}
.single-event-image span {
  display: block;
  font-family: "montserratsemi_bold";
  font-size: 26px;
  text-align: center;
}
.imagesNews_ {
  width: 250px;
  height: 190px;
}
.mySlides {
  display: none;
}

.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
}

.text {
  width: 100%;
  color: #f2f2f2;
  margin-bottom: -25px;
  font-size: 25px;
  line-height: 1.7;
  font-family: "Droid Arabic Kufi" !important;
  text-align: center !important;
  padding: 12px 12px;
  position: absolute;
  bottom: 46%;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  border-color: black;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #0c34cc;
  border:1px solid #fff
}

.fade2 {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 750px) {
  .text {
    font-size: 11px !important;
  }
}
</style>
