<template>
  <div
    class="latest-area section-padding"
    style="padding: 40px 0; background-color: rgb(52 152 219)"
    v-show="showActivity"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper section-title-wrapper1">
            <div class="section-title">
              <h3 style="margin-bottom: 24px; color: #fff">
                {{ CollegeActivity }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="event in Events"
          :key="event.postId"
          class="col-lg-4 col-md-6 col-12"
        >
          <div class="single-event-item">
            <div class="single-event-image">
              <a>
                <img
                  loading="lazy"
                  alt=""
                  width="100%"
                  height="auto"
                  v-bind:src="
                    'https://api2.yuniv.net:444/images/post/' + event.postImage
                  "
                />
                <!-- <span
                  ><span>{{ event.day1 }}</span
                  >{{ event.nT_MONTH1 }}</span
                > -->
              </a>
            </div>
            <div class="single-event-text single-event-text1">
              <h4>
                <a href="#" aria-label="title">{{ event.postTitle }} </a>
              </h4>
              <div class="single-item-comment-view">
                <span><i class="fa fa-clock-o"></i>{{ event.time1 }}</span>
                <span
                  ><i class="fa fa-map-pin"></i>{{ event.txtAdress1 }}
                </span>
              </div>
              <p style="text-align: justify">
                {{ event.postSubTitle.substring(0, 150) + "......" }}
              </p>

              <router-link
                href="#"
                aria-label="title"
                class="button-default"
                :to="{
                  name: 'Activity-with-id',
                  params: {
                    name: 'الأنشطة',
                    id: event.postID,
                  },
                }"
              >
                {{ Detials }}
              </router-link>
            </div>
          </div>
        </div>
        
        <div class="col-md-12 col-sm-12" style="text-align: left;padding:30px 0 0">
          <router-link
            href="#"
            aria-label="title"
            :to="{
              name: 'Events-With-Type',
              params: {
                type: 'Univ.Activity',
              },
            }"
            class="button-default button-large"
          >
            {{ BtnMore }} <i class="fa fa-chevron-left"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";

export default {
  data() {
    return {
      CollegeActivity: "",
      BtnMore: "",
      Events: [],
      Detials: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      showActivity: true,
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      self.CollegeActivity = self.translate[0]["Home"]["CollegeActivity"];
      self.Detials = self.translate[0]["Home"]["Detials"];

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Activity");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "3");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      }).then(function (response) {
        self.Events = response.data;
        if (self.Events.length != 0) {
          self.showActivity = true;
        } else {
          self.showActivity = false;
        }
      });
    },
  },
};
</script>
