<template>
  <div
    v-show="showservices"
    class="latest-area section-padding"
    style="background-color: #3498db; padding: 60px 0 40px"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3 style="margin-bottom: 24px; color: #fff">{{ Service }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          v-for="servic in services"
          :key="servic.postID"
          class="col-lg-3 col-md-6"
        >
          <div class="single-event-item">
            <div class="single-event-image">
              <a>
                <img
                  loading="lazy"
                  alt=""
                  width="100%"
                  height="auto"
                  v-bind:src="
                    'https://api2.yuniv.net:444/images/post/' + servic.postImage
                  "
                />
              </a>
            </div>
            <div class="single-event-text">
              <h4 style="margin-bottom: 10px">
                <a>{{ servic.postTitle }}</a>
              </h4>
              <!-- <p>{{ servic.postSubTitle }}</p> -->

              <router-link
                href="#"
                aria-label="title"
                class="button-default"
                :to="{
                  name: 'Service',
                  params: {
                    id: servic.postID,
                    type: servic.modID,
                  },
                }"
              >
                {{ Detials }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12" style="text-align: left;padding:30px 0 0">
          <router-link
            href="#"
            aria-label="title"
            :to="{
              name: 'Univ-with-Type',
              params: {
                type: 'Univ.Services',
              },
            }"
            class="button-default button-large"
          >
            {{ BtnMore }} <i class="fa fa-chevron-left"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";

export default {
  data() {
    return {
      showservices: true,
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      AboutUs: "",
      BtnMore: "",
      Detials: "",
      services: [],
      Service: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      self.Detials = self.translate[0]["Home"]["Detials"];
      self.Service = self.ArTranslat[0]["HeaderAndFooter"]["Service"];

      // services
      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Services");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "4");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      }).then(function (response) {
        self.services = response.data;
        //  console.log("ffffet",self.services)
        var arr = response.data;

        if (arr.length != 0) {
          self.showservices = true;
        } else {
          self.showservices = false;
        }

        //end services
      });
    },
  },
};
</script>
