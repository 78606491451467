<template>
  <!-- About Start -->
  <div
    class="about-page-area section-padding"
    v-show="showAbout"
    style="padding: 56px 0"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper" style="margin-bottom: 19px">
            <div class="section-title">
              <h3 :key="AboutName">{{ AboutName }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="about-text-container">
            <p :key="AboutSub">
              {{ AboutSub }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showAbout: true,
      AboutName: "",
      AboutSub: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("PostId", "");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Home.Sec.About");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      }).then(function (response) {
        var arr = response.data;
        if (arr.length != 0) {
          self.showAbout = true;
          self.AboutName = response.data[0]["postTitle"];
          self.AboutSub = response.data[0]["postSubTitle"];
        } else {
          self.showAbout = false;
        }
      });
    },
  },
};
</script>
