<template>
  <div>
    <div
      class="course-area section-padding course-page"
      style="padding: 40px 0; background-color: #f6f6f6; margin-bottom: 0px"
      v-show="showVideos"
    >
      <div class="container">
        <div class="row">

          <div class="col-lg-12 col-md-12">
            <div class="section-title-wrapper section-title-wrapper1">
              <div class="section-title">
                <h3>{{ YunivVideos }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="row">

          <div
            v-for="videos in Videos"
            :key="videos.postId"
            class="col-lg-4 col-md-6 col-12"
          >
            <div class="single-item single-item1 single-item-video">
              <div class="single-item-image overlay-effect">
                <a
                  @click="showVideo(videos.postID)"
                  data-toggle="modal"
                  data-target=".exampleModal"
                  ><img
                    loading="lazy"
                    :src="
                      'https://api2.yuniv.net:444/images/post/' +
                      videos.postImage
                    "
                    alt=""
                  />
                  <i class="fa fa-play-circle"></i>
                </a>
              </div>
              <div class="single-item-text single-item-text-video">
                <h4>{{ videos.postTitle }}</h4>
              </div>
              <div class="button-bottom">
                <a
                  style="cursor: pointer"
                  @click="showVideo(videos.postID)"
                  data-toggle="modal"
                  data-target=".exampleModal"
                  class="button-default"
                  >{{ watch }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12" style="text-align: left;padding:30px 0 0">
            <router-link
              href="#"
              aria-label="title"
              style="margin-bottom: 60px"
              :to="{
                name: 'Videos',
                params: {
                  type: 'Univ.Videos',
                },
              }"
              class="button-default button-large"
            >
              {{ BtnMore1 }} <i class="fa fa-chevron-left"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal start -->
    <div
      class="modal fade exampleModal" style="background: rgba(0,0,0,0.5);"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <iframe
              width="100%"
              style="height: 500px"
              class="mfp-iframe"
              id="postURL"
              frameborder="0"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal end -->
  </div>
</template>

<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";

export default {
  data() {
    return {
      YunivVideos: "",
      BtnMore1: "",
      watch: "",
      Videos: [],
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      showVideos: true,
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.YunivVideos = self.translate[0]["Home"]["YunivVideos"];
      self.BtnMore1 = self.translate[0]["Home"]["BtnMore"];
      self.watch = self.translate[0]["Home"]["watch"];

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Videos");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "3");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          var arr = response.data;
          self.Videos = response.data;
          if (arr.length != 0) {
            self.showVideos = true;
          } else {
            self.showVideos = false;
          }
        })

        .catch(function () {});
    },
    showVideo(id) {
      var bodyFormData3 = new FormData();
      bodyFormData3.append("check", "getPostById");
      bodyFormData3.append("PostId", id);
      bodyFormData3.append("Lang", localStorage.getItem("lang"));
      bodyFormData3.append("FbrnId", "");
      bodyFormData3.append("Type", "Univ.Videos");
      bodyFormData3.append("ParentId", "NTROOT0");
      bodyFormData3.append("PostName", "");
      bodyFormData3.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData3,
      }).then(function (response) {
        document
          .getElementById("postURL")
          .setAttribute("src", response.data[0]["url"]);
      });
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0;
}
.modal-header {
  border-bottom: 0;
}
.modal-header .close {
  color: #fff;
  opacity: 1;
  margin: -1rem -1rem -1rem auto;
}
.modal-content {
  background-color: transparent;
  border: none;
}
</style>
