<template>
  <div class="teachers-area" v-show="showOurPartner">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title" style="margin-bottom: 30px">
              <h3>{{ OurPartnerT }}</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="partners-image-slider"> -->
      <div class="row">
        <div
          v-for="partner in OurPartner"
          :key="partner.postId"
          class="col-lg-3 col-sm-1"
        >
          <div class="single-teacher-item single-teacher-item1">
            <div class="single-teacher-image single-teacher-image2">
              <a aria-label="title" :href="partner.url" target="_blank"
                ><img
                  width="100%"
                  height="auto"
                  loading="lazy"
                  v-bind:src="
                    'https://api2.yuniv.net:444/images/post/' +
                    partner.postImage
                  "
                  alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";

export default {
  data() {
    return {
      OurPartner: [],
      OurPartnerT: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      showOurPartner: true,
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.OurPartnerT = self.translate[0]["HeaderAndFooter"]["OurPartner"];

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("PostId", "");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.OurPartner");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "8");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      }).then(function (response) {
        var arr = response.data;
        if (arr.length != 0) {
          self.OurPartner = response.data;
          self.showOurPartner = true;
        } else {
          self.showOurPartner = false;
        }
      });
    },
  },
};
</script>
